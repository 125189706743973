<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Accounting Transaction</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Accounting Transaction</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="card" id="AccountingTransaction">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{ name: 'AccountingTransactionList' }"><i class="fas fa-list"></i> List</router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <div class="form-group">
                    <label class="control-label">Transaction type</label>
                    <input type="text" id="transactionType" name="transactionType" v-model="value.transactionType" class="form-control transactionType" placeholder="Transaction type" />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Source-Id</label>
                    <input type="text" id="sourceId" name="sourceId" v-model="value.sourceId" class="form-control sourceId" placeholder="Source-Id" />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Transaction date</label>
                    <input type="text" id="transactionDate" name="transactionDate" v-model="value.transactionDate" class="form-control transactionDate" placeholder="Transaction date" />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Entry date</label>
                    <input type="text" id="entryDate" name="entryDate" v-model="value.entryDate" class="form-control entryDate" placeholder="Entry date" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">
                Submit
              </button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MasterLayout from "@/components/layouts/MasterLayoutComponent";
import { DepartmentsAPI, CommonAPI } from "@/services/api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";

export default {
  name: "AccountingTransactionForm",
  components: {
    DatePicker,
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},

    value: {},
    pre: {},
  }),
  methods: {
    handleSubmission() {
      let _this = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          _this.is_loading = true;
          let api_url = "/accounting_transactions";
          let method = "POST";
          if (_this.is_edit) {
            api_url = "/accounting_transactions/" + _this.$route.params.id;
            method = "PUT";
          }
          axios({
            method: method,
            url: api_url,
            data: _this.value,
            headers: { "Content-Type": "application/json" },
          })
            .then(function (response) {
              _this.$toaster.success(response.data.message);
              _this.$router.push({ name: "AccountingTransactionList" });
            })
            .catch((errors) => {
              _this.$setErrorsFromResponse(errors.response.data);
            })
            .finally(() => {
              _this.is_loading = false;
            });
        }
      });
    },
    getDetail: function (id) {
      return new Promise((resolve) => {
        let api_url = "/accounting_transactions/" + id;
        let _this = this;
        axios
          .get(api_url)
          .then((response) => {
            _this.value = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            resolve(error);
          });
      });
    },
    async initialize() {
      this.is_loading = true;
      this.params = this.$route.params;

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id);
      }
      this.is_loading = false;
    },
  },
  mounted() {
    this.initialize();
  },
  created() {
    this.$emit("update:layout", MasterLayout);
  },
};
</script>

